.index {

  h3 {
    margin-bottom: @grid-gutter-width;
  }

  &-item {
    margin-bottom: ((@grid-gutter-width/2) + 5);
    padding-bottom: @grid-gutter-width/2;
    border-bottom: 1px #cbcbcb dashed;
    font-size: 16px;
    clear: both;
    &:last-child {
      border-width: 0;
    }
    
    &-image {
      max-width: 100%;
    }
    
    &-info {
      text-transform: uppercase;
      color: @gray-light;
      margin: 7px 0;
      .f-a;
      .clearfix();
      
      > div {
        float: left;
        margin-right: @grid-gutter-width;
      }
      &-categories {
        display: inline-block;
      }
      a {
        color: @gray-light;
      }
    }
    h3 {
      margin: 0;
    }
    &-readMore {
      .f-gh-b;
      margin: 7px 0;
      font-size: 20px;
      text-transform: uppercase;
    }
    &-attachmentsInfo {
      font-size: 13px;
      .clearfix();
      color: #999;
      &-item {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &-linkAll {
    float: left;
    margin: 20px 0 0 30px;
    font-size: 20px;
    text-transform: lowercase;
    line-height: 22px;
  }

  &-paging {
    padding: @grid-gutter-width/2 0;
    .clearfix();
    .f-gh-b;
    text-transform: uppercase;
    font-size: 16px;
    
    &-top {
      border-bottom: 1px #cbcbcb solid;
      margin-bottom: @grid-gutter-width/2;
    }
    &-bottom {
      padding-top: 0;
    }
    
    .right {
      float: right;
    }
    
  }
  
  &-form {
    padding: @grid-gutter-width/2 0;
    .row {
      margin-left: -5px;
      margin-right: -5px;
      
      *[class*="col"] {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
    
    &-contentType {
      padding: @grid-gutter-width/2 0 0;
      margin-bottom: -@grid-gutter-width/2;
    }
    
    label {
      display: block;
      position: relative;
    }
    .icon {
      position: absolute;
      right: 10px;
      top: 0;
      .fa {
        line-height: 34px;
        display: block;
        font-size: 18px;
        &-search {
          color: @red;
        }
        &-calendar {
          color: @blue;
        }
      }
    }
    .btn {
      .f-gh-b;
      text-transform: lowercase;
      font-size: 20px;
      padding: 1px 10px;
      width: 100%;
      .fa {
        line-height: 30px;
      }
    }
  }
  
  
  /*
  &-featured {
    &-large {
      &-item {
        display: none;
        &.active {
          display: block;
        }
      }
    }
    &-tabs {
      &-item {
        .clearfix;
        clear: left;
        background: #fff;
        margin-bottom: 5px;
        padding: @grid-gutter-width/2;
        
        &-image {
          width: 50%;
          float: left;
          padding-right: @grid-gutter-width/2; 
        }
        &-title {
          font-size: 16px;
        }
        
        &.active {
          background: @blue;
        }
        &.active &-title {
          a {
            color: #fff;
          }
        }
      }
    }
  }
  */
}




.multimedia {

  margin: @grid-gutter-width 0;
  padding-top: 10px;
  

  > .row {
    letter-spacing: -0.4em;
    
    > *[class*="col"] {
      letter-spacing: 0;
      display: inline-block;
      vertical-align: top;
      float: none;
    }
  }

  &-item {
    
    text-transform: uppercase;
    margin-bottom: @grid-gutter-width;
    
    &-caption {
      .f-gh-b;
      font-size: 20px;
      padding: 10px;
      color: @blue;
    }
    &-more {
      .f-gh-b;
      font-size: 20px;
      padding: 0 10px;
    }
    
    > a {
      display: block;
      position: relative;
    }
    &-image {
      &-playIcon {
        position: absolute;
        left: 40%;
        top: 30%;
        font-size: 50px;
        color: #fff;
        opacity: 0.6;
      }
    }
  }
  
}

.gallery {
  &-item {
    &-lightbox {
      
      &-title {
        color: #092c74;
        margin: @grid-gutter-width/2 0;
        font-size: 20px;
        .f-gh-b;
      }
      &-download {
        text-transform: uppercase;
        font-size: 20px;
        .f-gh-b;
      }
    }
  }
}
/* RSS LIST */
.rssList {
  margin: 15px -15px/2;
  padding: 0 15px/2;
  
  li {
    list-style-type: none !important;
  }
}
.rssList a {
  display: block;
  margin: 0 -15px/2;
  padding: 7px 15px/2;
}
.rssList a:hover{
  background: #f0f0f0;
  text-decoration: none;
}
.rssList img {
  float: right;
  cursor: pointer;
}
.rssList li {
}

.emailAlerts {

  max-width: @screen-xs-max;

  input[type=checkbox],
  input[type=radio] {
    float: left;
    margin: 4px 6px 0 0;
    position: static;
  }
  #recaptcha_widget_div {
    margin-bottom: 15px;
    margin-left: -2px;
  }
  
  .menu {
    li {
      list-style-type: none;
	  margin-bottom: inherit !important;
	  
      :before {
	    content: "" !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
	  }
	}
  }
  input[type="radio"]:checked + label {
    background: none;
  }
  
}
.errors {
  list-style-type: none;
  margin: 0;
  padding: 0;
  color: #ff0000;
}
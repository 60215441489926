﻿.bios {
  padding: @grid-gutter-width 0;
  
  h2 {
    text-align: center;
  }
  
  &-featured {
    &-item {
      color: #555;
      margin: @grid-gutter-width 0;
      
      &-image {
        img {
          border-radius: 50%;
        }
      }
      h3 {
        font-size: 25px;
        text-transform: uppercase;
        margin: 0;
        a {
          color: @red;
        }
      }
      &-subtitle {
        font-size: 23px;
        margin-bottom: @grid-gutter-width/2;
      }
      &-quote {
        font-family: "Georgia", "Times New Roman", serif;
        font-style: italic;
        font-size: 20px;
        &:before {
          content: "“";
          font-size: 83px;
          font-style: none;
          color: #a8a8a8;
          float: left;
          margin: -22px 7px -45px -5px;
		 
        }
		p {
		  padding-left: 40px;
		}
      }
      &-linkMore {
        font-size: 16px;
        text-transform: uppercase;
        .f-gh-b;
        clear: both;
        padding-top: @grid-gutter-width/2;
      }
    }
  }
  
  > .row {
    letter-spacing: -0.4em;
    
    > *[class*="col"] {
      letter-spacing: 0;
      display: inline-block;
      vertical-align: top;
      float: none;
    }
    
  }
  
  &-item {
    margin: @grid-gutter-width/2 0;
    
    &-image {
      /* border: #f2f4f4 3px solid; */
      
      img {
        display: block;
        margin: 0 auto;
        border-radius: 50%;
		width: 100px;
		height: auto;
      }
    }
    &-text {
      padding: @grid-gutter-width/2;
      /* background: #f2f4f4; */
      text-align: center;
      
      h3 {
        margin: 0 0 5px;
        font-size: 14px;
        a {
          color: @red;
          text-transform: uppercase;
        }
      }
      &-subtitle {
        font-size: 12px;
      }
    }
  }
  
}
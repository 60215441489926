@navXS: 225px;

.f-a {
  font-family: Arial, Helvertica, sans-serif;
}
.f-gh-b {
  font-family: "Gotham SSm A", Helvetica, Arial, sans-serif;
	font-weight: 700;
}
.f-gh-bk {
  font-family: "Gotham SSm A", Helvetica, Arial, sans-serif;
	font-weight: 400;
}
.f-gh-lt {
  font-family: "Gotham SSm A", Helvetica, Arial, sans-serif;
	font-weight: 300;
}
.f-gh-m {
  font-family: "Gotham SSm A", Helvetica, Arial, sans-serif;
	font-weight: 500;
}

body {
  /*
  @media screen and (max-width: @screen-sm-max) {
    transition: transform 0.3s ease;
    &.navOpen {
      transform: translateX(-@navXS);
    }
  }
  */

}

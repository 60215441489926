.socialTab {
  
  .nav-tabs {
    .menu-list;

    @media screen and (max-width: @screen-xs-max) {

    }
    @media screen and (min-width: @screen-sm) {
      float: right;
      margin-top: -46px;
    }
    
    li {
      display: inline-block;
      font-size: 20px;
      
      a {
        .btn;
        .btn-default;
        border-width: 2px;
        text-decoration: none;
        display: block;
        color: @gray-light;
        padding-top: 3px;
        padding-bottom: 3px;
        
        @media screen and (min-width: @screen-sm) {
          margin-left: 20px;
        }
        
        span {
          .hidden-xs;
          .f-gh-b;
          display: inline-block;
          line-height: 40px;
          text-transform: uppercase;
          vertical-align: middle;
        }
        
        .fa {
          font-size: 40px;
          vertical-align: middle;
          margin-right: 5px;
          @media screen and (max-width: @screen-xs-max) {
            width: 60px;
          }
        }
        
      }
      
      &.active {
        a {
          border-color: @red;
          outline: none;
          color: @blue;
          background: @white;
          
          .fa {
            &-twitter {
              color: @twitter;
            }
            &-facebook {
              color: @facebook;
            }
            &-youtube {
              color: @youtube;
            }
            &-instagram {
              color: @instagram;
            }
          }
        }
      } 
    }
  }
  
  &-row {
   .make-row();
  }
  
  .tab-pane {
    display: none;
    padding: @grid-gutter-width 0;
    
    &.active {
      display: block;
    }
  }
  
  
  .embed {
    .clearfix();
    .make-sm-column(3);
    margin-bottom: @grid-gutter-width;
    display: inline-block;
    float: none;
    vertical-align: top;
		
		&:nth-child(4n+1) {
			clear: left;
		}
    
    .thumb {
      display: block;
      margin: 5px 0;
    }
    
    .provider {
      display: none;
    }
    
    iframe {
      max-width: 100%;
     .content-aside & {
       max-height: 200px;
     }
    }
    
    
  }
  
  #instafeed {
    .caption {
      padding: 10px 0;
    }
    .embed {
      display: inline-block;
      vertical-align: top;
      a {
        display: block;
        border: 1px #ddd solid;
      }
    }
  }
  
  #twitterfeed {
    span[data-scribe="element:name"] {
      display: none;
    }
    .user {
      img {
        float: left;
        margin: 0 10px 0 0;
      }
    }
    .twitter-box {
      .clearfix();
      margin: 10px 0;
      text-align: left;
      overflow: hidden;
      
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px #ddd solid;
    
      &:last-child {
        border-bottom-width: 0;
      }
    
      &-media {
        margin-bottom: 10px;
      }
    }
  
    .timePosted {
      float: left;
      font-size: 12px;
    }
    .interact {
      float: left;
      font-size: 12px;
      opacity: 0.6;
      margin-bottom: 0;
      
      a {
        outline: none;
      }
      
      .twitter_reply_icon,
      .twitter_retweet_icon,
      .twitter_fav_icon {
        text-indent: -9999px;
        text-decoration: none;
        text-align: center;
        display: inline-block;
        width: 20px;
        margin: 0 5px;
        
        &:before {
          float: left;
          text-indent: 0;
          font-family: "fontAwesome";
          font-size: 16px;
        }
      }
      .twitter_reply_icon {
        &:before {
          content: "\f112";
        }
      }
      .twitter_retweet_icon {
        &:before {
          content: "\f079";
        }
      }
      .twitter_fav_icon {
        &:before {
          content: "\f005";
        }
      }
    }
  }
}

#facebookfeed {
  .description {
    display: none;
  }
}
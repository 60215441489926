body.scrolled {
  /*padding-top: 160px;*/
}

img {
  max-width: 100%;
  height: auto;
}

.menu-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.kioskText {
  padding: 20px 0;
}

table {
  td {
    vertical-align: top;
  }
}

.kiosk {
  &-container {
    padding: 0;
  }
  
  &-aside, &-title {
    background: @gray-lighter;
    padding: @grid-gutter-width/3 @grid-gutter-width/2;
  }
  &-text {
    padding: @grid-gutter-width/2;
  }
}

.hero,
.bottom {
  background: @gray-lighter;
  padding: 30px 0;
}

.stories {
  &-item {
    margin-bottom: @grid-gutter-width;
    &-description {
      font-size: 16px;
    }
  }
}

.content {
  &-wrapper
  
  	ul li:before {
	  content: '\25A0';
	  color: #c54b25;
	  margin-left: -20px;
	  margin-right: 10px;
	}
	ul {
    padding-left: 0;
    li {
      list-style-type: none;
      margin-bottom: 1em;
      list-style-position: inside;
      overflow: hidden;
      padding-left: 20px;
    }
  }
  &-aside {
    padding: @grid-gutter-width 0;
  }
  
  &-wrapper {
    .f-a;
    font-size: 16px;
  }
  
  &-kiosk {
    margin: @grid-gutter-width 0;
    h2:first-child {
      margin: @grid-gutter-width/2 0;
    }
  }
}

.bottom {
  &-subscribe {
    form {
      position: relative;
      margin: @grid-gutter-width/2 0;
      max-width: 250px;

      button {
        position: absolute;
        top: 8px;
        right: 8px;
        background: none;
        border-width: 0;
        color: @red;
      }
    }

  }
}

.links {
  li {
    max-width: 200px;
    border-bottom: 1px #ccc solid;
    padding: 5px 0;
    &:last-child {
      border-bottom-width: 0;
    }
    a {
      .f-gh-b;
      font-size: 16px;
      color: @gray-light;
    }
  }
}

.socialLinks {
    ul {
      letter-spacing: 15px;
	  max-width: 260px;
      
      li {
        display: inline-block;
		margin-bottom: 1em;
        
        a {
          display: block;
          width: 50px;
          height: 50px;
          line-height: 50px;
          text-align: center;
          font-size: 25px;
          background: @blue;
          border-radius: 25px;
          color: #fff;
          text-decoration: none !important;
        }
        .fa {
          line-height: 50px;
          text-align: center;
          &:before {
            display: block;
            margin: 0 auto;
            width: 20px;
          }
        }
      }
    }
}

.contact {
  &-item {
    font-size: 16px;
    &-name {
      .f-gh-b;
      text-transform: uppercase;
    }
    &-phone {
    }
  }
}

.breadcrumb {
  font-size: 16px;
  font-family: Helvetica, Arial, sans-serif;
  a {
    outline: none;
    &:after {
      content: "/";
      display: inline-block;
      margin: 0 10px;
      color: #999;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}

.embed {
  .clearfix();
  &-share {
    float: right;
  }
  &-action {
    float: left;
  }
  body > &,
  body > & &-media,
  body > & &-media video  {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  body > & &-title,
  body > & &-caption,
  body > & &-action,
  body > & &-share {
    display: none;
  }
}
.article {
  color: #555;

  &-subtitle {
    margin-top: @grid-gutter-width/2;
    font-size: 19px;
    font-family: Helvetica, Arial, sans-serif;
    
    &-page {
      font-size: 19px;
	  line-height: 1.4em;
	  max-width: 75%;
    }
    
  }

  &-thmb {
    float: left;
    margin: 22px 20px 0 0;
    
    img {
      border-radius: 50%;
	  width: 100px;
	  height: auto;
    }
  }
  
  &-body {
    font-size: 16px;
    font-family: Helvetica, Arial, sans-serif;
    margin-bottom: @grid-gutter-width;
    p, 
    ul, 
    ol, 
    .embed_container {
      margin-bottom: 20px;
    }
    .embed_container {
      max-width: 768px;
      margin-left: auto;
      margin-right: auto;
    }
  }  
  
  &-share {
    .clearfix();
    .article-kiosk-title {
      margin-right: 15px;
    }
  }

  &-download {
    a {
      font-size: 16px;
      font-family: Helvetica, Arial, sans-serif;
    }
    hr {
      margin: 10px 0;
    }
  }
  
  &-recent {
    &-item {
      margin: @grid-gutter-width 0;
      &-image {
        
      }
      h3 {
        line-height: 150%;
        margin: 10px 0;
        .f-gh-b;
      }
      &-readMore {
        .f-gh-b;
        font-size: 20px;
        text-transform: uppercase;
      }
    }
  }
  
  &-kiosk {
    margin: @grid-gutter-width/2 0;
    &-title {
      .f-gh-b;
      color: @blue;
      text-transform: uppercase;
      font-size: 20px;
    }
  }
  
  &-tags {
    .f-gh-b;
    font-size: 20px;
    text-transform: uppercase;
    a {
    }
  }
  
  
  /*
  **************
  Article w/HERO 
  **************
  */
  
  &-hero {
    height: 500px;
    background-size: cover;
    background-position: 50% 50%;
    
    > .container {
      position: relative;
      height: 100%;
    }
    
    &-title {
      position: absolute;
      left: 15px;
      right: 15px;
      bottom: 40px;
      
      &-text {
        font-size: 60px;
        .f-gh-m;
        color: white;
        text-shadow: 0 0 1px #000;
        text-transform: none;
        margin: 0 0 15px;
        
        @media (max-width: @screen-xs-max) {
          font-size: 40px;
        }
        
      }
      
      &-subtitle {
        font-size: 30px;
        .f-gh-m;
        color: white;
        text-shadow: 0 0 1px #000;
        margin: 0 0 15px;
      }
      
    }
    
    &-date {
      position: absolute;
      left: 15px;
      bottom: 0;
      background: #b71f20;
      font-size: 15px;
      color: white;
      padding: 4px 15px;
    }
    
  }
  
  &-h_body {
    border: 1px #dedede solid;
    border-top-width: 0;
    @media (min-width: @screen-md) {
      padding: 30px 60px 60px;
    }
    @media (min-width: @screen-sm) and (max-width: @screen-md) {
      padding: 30px 30px 30px;
    }
    @media (max-width: @screen-xs-max) {
      padding: 15px;
    }
    margin-bottom: 40px;
    
    h2, 
    h3 {
      clear: both;
    }
    
    &-share {
      margin-bottom: 15px;
    }
    
    .textImage {
      margin-bottom: 20px !important;
      max-width: 100%;
      &-left {
        margin-left: 0 !important;
        margin-right: 20px !important;
      }
      &-right {
        margin-right: 0 !important;
        margin-left: 20px !important;
      }
      @media (max-width: @screen-sm) {
        float: none !important;
        display: block !important;
        margin: 30px auto !important;
        
        img {
          float: none !important;
          display: block !important;
        }
      }
    }
    
  }
  
}

.attachments {
  &-tab {
    &-item {
      display: none;
      
      &-text {
        padding: @grid-gutter-width/2 0;
        .f-gh-b;
        text-transform: uppercase;
        
        &-title {
          font-size: 20px;
          color: @blue;
          line-height: 130%;
          
        }
        &-actions {
          margin-top: 10px;
          font-size: 18px;
          letter-spacing: @grid-gutter-width/2;
          a {
            letter-spacing: 0;  
          }
        }
      }
      
      
      &.active {
        display: block;
      }
    }
  }
  .nav-tabs {
    .menu-list;
    li {
      display: inline-block;
      img {
        border: 2px #fff solid;
      }
      &.active {
        img {
          border: 2px @red solid;
        }
      }
    }
  }
  
  &-list {
    .article-files & {
      display: flex;
      align-items: flex-end;
      margin: 0 (-15px);
      padding: 30px 0 0;
      flex-wrap: wrap;
      
      &-item {
        
        @media (min-width: @screen-lg) {
          flex: 0 0 25%;
          max-width: 25%;
        }
        @media (max-width: @screen-md) and (min-width: @screen-sm) {
          flex: 0 0 50%;
          max-width: 50%;
        }
        @media (max-width: @screen-xs) {
          flex: 0 0 100%;
          max-width: 100%;
        }
        
        padding: 15px;
        margin-bottom: 15px;
        a {
          color: #092c74;
        }
        &-body {
          &-image {
          }
        }
        &-action {
          a {
            color: #d25925;
            display: inline-block;
            &:first-child {
              margin-right: 30px;
            }
          }
          text-transform: uppercase;
          .f-gh-b;
          font-size: 14px;
        }
      }
    }
  }
  
}

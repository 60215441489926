.footer {
  background: @blue;
  padding: 10px 0;
  
  &-links,
  &-copy {
    font-size: 12px;
    line-height: 40px;
    color: #fff;
    
    p {
      margin: 0;
    }
  }
  a {
    margin-right: 21px;
    display: inline-block;
    color: #fff;
    &:hover {
      text-decoration: none;
    }
  }
  
 
}
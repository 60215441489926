.header {
  background: @blue;
  box-shadow: 0 ((@headerAll)-(@headerTop)) 0 0 @white inset;
  
  @media screen and (max-width: 400px) {
    box-shadow: 0 91px 0 0 @white inset;
  }

  .scrolled & {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: none;
    height: @headerTop;
    z-index: 9;
    
    & + div {
      margin-top: @headerAll;
    }
    
    &-logo,
    &-link,
    &-search {
      display: none;
    }
  }
  
  &-logo {
    float: left;
    margin: 20px 0 23px;
	max-width: 250px;
    
    @media screen and (max-width: 400px) {
      width: 180px;
    }
  }
  
  &-search {
    margin-top: 30px;
    margin-left: 33px;
    float: right;
    height: 30px;
    
    &-link {
      background: none;
      border-width: 0;
      z-index: 1;
      font-size: 18px;
      position: relative;
      z-index: 1;
      float: right;
      margin-right: 5px;
      
      .fa {
        font-size: 20px;
        line-height: 34px;
      }
    }
    &-input {
      position: absolute;
      z-index: 0;
      right: 0;
      top: 0;
      @media screen and (min-width: @screen-md) {
        width: 100%;
      }
      @media screen and (max-width: @screen-sm-max) {
        width: 290px;
        transform: scaleX(0);
        transform-origin: 100% 50%;
        transition: transform 0.3s ease;
      }
    }
    &-open &-input {
      @media screen and (max-width: @screen-sm-max) {
        transform: scaleX(1);
      }
    }
    
    form {
      float: right;
      position: relative;
      max-width: 100%;
      @media screen and (min-width: @screen-md) {
        width: 350px;
      }
    }
  }
  
  &-link {
    float: right;
    font-size: 16px;
    text-transform: uppercase;
    .f-gh-m;
    .hidden-xs;
    margin-top: 30px;
    line-height: 34px;
  }
  &-nav {
    clear: both;
    .clearfix();
    
    &-searchbar {
      padding: 10px 0;
      form {
        position: relative;
      }
      &-link {
        position: absolute;
        top: 0;
        right: 10px;
        background: none;
        border-width: 0;
        z-index: 1;
        font-size: 18px;
      
        .fa {
          font-size: 20px;
          line-height: 34px;
        }
      }
    }
    
    &-link {
      float: right;
      font-size: 26px;
      color: @white;
      .hidden-md;
      .hidden-lg;
      .fa {
        line-height: 50px;
        display: block;
      }
    }
    
    &-title {
      text-transform: uppercase;
      line-height: @headerTop;
      .f-gh-m;
      font-size: 30px;
      float: left;
      a {
        color: @white;
        text-decoration: none;
      }
    }
    > ul {
      .hidden-xs;
      .hidden-sm;
    }
    ul.indent0 {
      @media screen and (max-width: @screen-sm-max) {
        position: absolute;
        top: @headerAll;
        left: 0;
        right: 0;
        display: none;
        background: @blue;
        z-index: 10;
        @media (max-width: 400px) {
          top: 141px;
        }
        
        li {
          display: block;
          max-width: 720px;
          margin: 0 auto;
          @media (max-width: ((@screen-xs-max)+(@grid-gutter-width))) {
            padding-left: @grid-gutter-width/2;
            padding-right: @grid-gutter-width/2;
          }
          
          a, span {
            font-size: 16px;
            display: block;
            padding: 10px 0;
            color: #fff;
            font-size: 16px;
            border-bottom: 1px rgba(255,255,255,0.7) solid;
          }
          &:last-child {
            a {
              border-bottom-width: 0;
            }
          }
        }
        
      }
      @media screen and (min-width: @screen-md) {
        float: right;
        .menu-list;
        > li {
          display: inline-block;
          padding-left: 23px;
          position: relative;
          > a,
          > span {
            display: block;
            text-transform: uppercase;
            text-decoration: none;
            color: #fff;
            .f-gh-m;
            font-size: 16px;
            @media (min-width: @screen-md) and (max-width: @screen-md-max) {
              font-size: 14px;
            }
            line-height: @headerTop;
            position: relative;
          
            &:after {
              content: "";
              position: absolute;
              bottom: 7px;
              left: 10px;
              right: 10px;
              height: 2px;
              background: #fff;
              transform: scaleX(0);
              transform-origin: 50% 50%;
              transition: transform ease 0.3s;
              opacity: 0.7;
            }

            &.active {
              &:after {
                opacity: 1;
                transform: scaleX(1);
              }
            }
            &:hover,
            &:focus {
              &:after {
                transform: scaleX(1);
              }
              ~ .header-nav-submenu {
                max-height: 1000px;
              }
            } 
          }
        }
      }
    }
    ul.indent1 {
      .menu-list;
      padding: 7px 0;
      @media screen and (min-width: @screen-md) {
        padding: 10px 0;
      }
     
      > li {
        > a,
        > span {
          display: block;
          color: @white;
          padding: 5px 15px;
          text-decoration: none;
        }
      }
    }
    &-opened {
      > ul {
        @media screen and (max-width: @screen-sm-max) {
          display: block !important;
        }
      }
      .scrolled & {
        > ul {
          @media screen and (max-width: @screen-sm-max) {
            top: @headerTop;
          }
        }
      }
    }
    &-submenu {
      @media screen and (min-width: @screen-md) {
        position: absolute;
        left: 50%;
        top: 100%;
        min-width: 250px;
        transform: translateX(  -50%);
        background: @blue;
        text-align: center;
        z-index: 1;
        
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-in;
        &:hover,
        &.hovered {
          max-height: 10000px;
          transition: max-height 0.3s ease-out;
        }
        
      }
    }
  }
}
.facts {
  margin: @grid-gutter-width 0;
  padding-top: 10px;
  background: url("../images/dash.png") repeat-x left top;
  

  > .row {
    letter-spacing: -0.4em;
    
    > *[class*="col"] {
      letter-spacing: 0;
      /* display: inline-block;
      vertical-align: top;
      float: none; */
    }
  }

  &-item {
    position: relative;
    background-color: #f2f4f4;
    height: 200px;
    margin: @grid-gutter-width/2 0;
    
    a {
      height: 100%;
      display: block;
      padding: 22px;
      box-shadow: 0 0 0 20px #f2f4f4 inset, 0 0 0 24px #fff inset;
    }
    
    h3 {
      background: rgba(255,255,255,0.6);
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      padding: 0 @grid-gutter-width;
      display: flex;
      flex-direction: column;
      justify-content: center;
      
      > span {
        display: flex;
        justify-content: center;
		color: #c54b25;
      }
    }
  }
}
.devc {
  display: none;
}

.kit {

  /* BANNER */
  &-banner {
    position: relative;

    &-image {
      max-width: 100%;
      height: auto;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: fade(@blue, 33%);
      }
    }

    &-box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%)translateY(-50%);

      @media screen and (max-width: 1200px) {
        width: 100%;
      }

      &-wrapper {
        border: 2px solid #fff;
        padding: 30px;
        text-align: center;
        width: 440px;
        height: 440px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media screen and (max-width: 1200px) {
          width: auto;
          height: auto;
          padding: 0;
          border: none;
        }

      }

      &-title {
        .f-gh-b;
        font-size: 60px;
        text-transform: uppercase;
        color: #fff;

        @media screen and (max-width: 767px) {
          font-size: 40px;
        }
      }

      &-caption {
        .f-gh-lt;
        font-size: 30px;
        text-transform: uppercase;
        color: #fff;

        @media screen and (max-width: 767px) {
          font-size: 20px;
        }
      }
    }
  }

  /* OVERVIEW */
  &-overview {
    .f-gh-lt;
    font-size: 18px;
    max-width: 900px;
    margin: 30px auto;

    h2 {
      max-width: 400px;
      padding-bottom: 15px;
      margin: 0 auto 30px;
      border-bottom: 2px solid;
    }
  }

  /* BOXES  */
  &-boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -15px;
    margin-right: -15px;
  }

  &-box {
    flex: 0 0 300px;
    max-width: 300px;
    margin-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;

    &-wrapper {
      display: block;
      height: 300px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: 3px solid @gray-lighter;
      padding: 20px;
      transition: all 0.3s ease;

      &:hover, &:focus {
        text-decoration: none;
        background-color: @gray-lighter;
        transform: translateY(-5px);
      }
    }

    &-image {
      text-align: center;

      img {
        max-height: 100px;
        width: auto;
      }
    }

    &-text {
      text-align: center;

      &:before {
        content: "";
        background-color: @blue;
        display: block;
        width: 50px;
        height: 3px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 15px;
      }
    }

    &-title {
      .f-gh-lt;
      font-size: 18px;
      color: @text-color;
    }

    &-cta {
      .f-gh-b;
      font-size: 16px;
      text-transform: uppercase;
      color: @red;
    }
  }

  /* NAVBAR */
  &-navbar {
    background-color: @gray-lighter;
    padding-top: 15px;
    padding-bottom: 15px;
    position: sticky;
    top: 50px;
    z-index: 100;
  }

  &-nav {
    display: flex;
    align-items: center;
    justify-content: center;

    &-title {
      .f-gh-b;
      color: @blue;
      font-size: 20px;
      margin: 0;
    }

    &-menu {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;

      @media screen and (max-width: 1023px) {
        display: none;
      }

      a {
        .f-gh-lt;
        font-size: 16px;
        color: @blue;
        display: block;
        padding: 15px 30px;

        &.active {
          .f-gh-b;
          color: @red;
        }
      }
    }
  }

  /* PANELS */
  &-panels {
    display: flex;

    @media screen and (max-width: 1023px) {
      flex-wrap: wrap;
    }
  }

  &-panel {
    &-image {
      flex: 0 0 50%;
      max-width: 50%;
      order: 2;
      position: sticky;
      top: 245px;
      left: auto;
      right: 0;
      height: 100vh;
      overflow: hidden;
      background-color: @blue;

      @media screen and (max-width: 1023px) {
        flex: 0 0 100%;
        max-width: 100%;
        order: 1;
        position: relative;
        height: auto;
        top: unset;
      }

      &-wrapper {
        position: relative;

        .kit-banner-box {
          top: 300px;
          z-index: 100;
        }
      }

      &-cta {
        text-align: center;
        margin-top: 20px;
      }
    }

    &-content {
      flex: 0 0 100%;
      max-width: 100%;
      order: 1;

      @media screen and (max-width: 1023px) {
        flex: 0 0 100%;
        max-width: 100%;
        order: 2;
      }
    }

    /* SECTIONS */
    &-wrapper {
      padding: 60px;
      max-width: 1170px;
      margin-left: auto;
      margin-right: auto;

      @media screen and (max-width: 1023px) {
        padding: 30px;
      }

      .row {
        display: flex;
        flex-wrap: wrap;
      }
    }

    &-title {
      .f-gh-b;
      font-size: 24px;
      color: @red;
      margin: 0 0 30px;
    }

    &-sections &-section:nth-child(even) {
      background-color: @gray-lighter;
    }
  }

  /* ARTICLES */
  &-article {
    margin: 0 0 30px;

    &-title {
      .f-gh-b;
      font-size: 18px;
      line-height: 1.4;
      font-weight: 400;
      text-transform: none;
      margin: 0 0 15px;
      color: @blue;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      margin: 0 0 30px;
    }
  }

}

.scrolled {
  .kit-panel-image {
    @media screen and (min-width: 1024px) {
      top: 134px;
    }
  }
}

/* GALLERY SLIDER */

.gallery-slide {
  position: relative;

  a {
    display: block;
  }

  a:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: fade(@blue, 33%);
  }
}

/* DOCUMENTS */
.doc {
  &-slider {
    &-item {
      width: 150px;
    }

    .owl-prev {
      position: absolute;
      top: 25%;
      left: -40px;
      font-size: 30px !important;
      color: @blue !important;

      &:hover, &:focus {
        background-color: transparent !important;
      }
    }

    .owl-next {
      position: absolute;
      top: 25%;
      right: -40px;
      font-size: 30px !important;
      color: @blue !important;

      &:hover, &:focus {
        background-color: transparent !important;
      }
    }
  }

  &-item {
    color: @blue;
  }

  &-thumb {
    height: 200px;
    width: 150px;
    padding: 5px;
    background-color: #fff;
    box-shadow: 3px 3px 1px 0px rgba(0, 0, 0, .1);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &-icon {
    max-width: 100%;
    height: auto;
  }

  &-title {
    .f-gh-lt;
    font-size: 14px;
    text-align: center;
    margin: 15px 0;
  }
}


.btn-alt {
  display: inline-block;
  background-color: @red;
  color: #fff;
  .f-gh-b;
  font-size: 16px;
  text-align: center;
  padding: 10px 20px;

  &:hover, &:focus {
    background-color: darken(@red, 15%);
    border-radius: 0;
    color: #fff;
    text-decoration: none;
  }
}

.nav {
  list-style: none;
  padding: 0;
  margin: 0;
}

.bottom, .footer {
  position: relative;
}

video {
  max-width: 100%;
  height: auto;
}
